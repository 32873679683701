<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
    >
      <v-card>
        <!-- Header -->
        <v-card-text class="py-7 px-8">
          <v-list
            three-line
            max-height="800"
            style="overflow-y: auto;"
          >
            <!-- <v-subheader
              v-text="t('users.users')"
            ></v-subheader> -->

            <div
              v-for="(item, index) in dataComputed"
              :key="index"
            >
              <v-list-item>
                <v-list-item-avatar
                  width="60"
                  height="60"
                >
                  <v-img
                    :src="item.avatar"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.username }}</v-list-item-title>
                  <v-list-item-subtitle
                    class="text-caption"
                  >
                    {{ item.views_str }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="text-caption"
                  >
                    {{ item.last_view_str }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- <v-list-item-action>
                  <v-list-item-action-text v-text="item.last_view_str"></v-list-item-action-text>
                </v-list-item-action> -->
              </v-list-item>

              <v-divider
                v-if="((dataComputed.length - 1) !=+ index)"
              />
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { computed } from '@vue/composition-api'
import { mdiSendOutline, mdiCurrencyUsd, mdiVideoOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const dataComputed = computed(() => props.dataParams)
    const { t } = useUtils()

    return {
      dataComputed,

      // i18n
      t,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiVideoOutline,
      },
    }
  },
}
</script>
