<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
            class="align-start d-flex"
          >
            <v-text-field
              v-model="dataComputed.facility_name"
              class="input-avatar"
              :label="t('facilities.facility')"
              dense
              outlined
              readonly
            >
              <template v-slot:prepend>
                <v-avatar
                  size="36px"
                  class="ma-0"
                >
                  <img
                    alt="Avatar"
                    :src="dataComputed.facility_avatar"
                  >
                </v-avatar>
              </template>
            </v-text-field>
          </v-col>

          <v-col
            md="3"
            cols="12"
            class="align-center"
          >
            <v-text-field
              v-model="dataComputed.field_name"
              :label="t('fields.field')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="align-center justify-center d-flex"
          >
            <v-chip
              :color="resolveStatusVideo(dataComputed.status)"
              :class="`${resolveStatusVideo(dataComputed.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize justify-center"
              style="width: 100%;"
              readonly
            >
              {{ dataComputed.status_str }} {{ t('videos.video') }}
            </v-chip>
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.video_date"
              :label="t('videos.date')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.video_time_str"
              :label="t('videos.time')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.start_time"
              :label="t('transactions.start_time')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.end_time"
              :label="t('transactions.end_time')"
              dense
              outlined
              readonly
            >
            </v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.transaction"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.transaction.transaction_date"
              :label="t('transactions.order_date')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.transaction && dataComputed.transaction.user"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="computedUser"
              :label="t('users.fullname')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.transaction"
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="dataComputed.transaction.amount"
              :label="t('transactions.amount')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.transaction"
            cols="12"
            md="2"
            class="align-center justify-center d-flex"
          >
            <v-chip
              :color="resolveStatusVariant(dataComputed.transaction.status)"
              :class="`${resolveStatusVariant(dataComputed.transaction.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize justify-center"
              style="width: 100%;"
              readonly
            >
              {{ dataComputed.transaction.status_str }} {{ t('transactions.order') }}
            </v-chip>
          </v-col>

          <v-col
            v-if="dataComputed.transaction && dataComputed.transaction.payment"
            cols="12"
            md="2"
            class="align-center justify-center d-flex"
          >
            <v-btn
              class=""
              color="secondary"
              small
              block
              @click="onShow(dataComputed)"
            >
              {{ t('payment_methods.payment_info') }}
            </v-btn>
          </v-col>

          <!-- <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refCopy"
              v-model="dataComputed.landing_app_link"
              :label="t('fields.link_app_landing')"
              dense
              outlined
              readonly
              :append-icon="icons.mdiContentCopy"
              @click:append="copy('landing_app_link')"
            ></v-text-field>
          </v-col> -->

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refCopyVideo"
              v-model="dataComputed.video_share_link"
              :label="t('fields.link_video')"
              dense
              outlined
              readonly
              :append-icon="icons.mdiContentCopy"
              @click:append="copy('video_share_link')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown, mdiContentCopy } from '@mdi/js'
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { getPaymentById, getPaymentUserApp } from '@api'
import { copyText } from 'vue3-clipboard'
import { success, error } from '@core/utils/toasted'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    dataTransaction: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const form = ref(null)
    const { router } = useRouter()
    const { t } = useUtils()
    const vm = getCurrentInstance().proxy
    const refCopy = ref(null)
    const refCopyVideo = ref(null)
    const initData = ref({
      end_time: null,
      facility_address: null,
      facility_avatar: null,
      facility_name: null,
      field_name: null,
      id: null,
      left_thumbnail: null,
      link: null,
      right_thumbnail: null,
      start_time: null,
      status: null,
      status_str: null,
      thumbnail: null,
      transaction: {
        transaction_date: null,
        user: {
          fullname: null,
        },
        user_app: {
          fullname: null,
        },
      },
      video_date: null,
      video_time: null,
      video_time_str: null,
    })

    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))
    const computedUser = computed(() => {
      if (dataComputed.value.transaction && dataComputed.value.transaction.user) return dataComputed.value.transaction.user.fullname
      if (dataComputed.value.transaction && dataComputed.value.transaction.user_app) return dataComputed.value.transaction.user_app.fullname

      return ''
    })

    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const resolveStatusVariant = status => {
      if (status === 'primary') return 'primary'
      if (status === 'N') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'warning') return 'warning'
      if (status === 'F') return 'info'
      if (status === 'P') return 'success'

      return 'primary'
    }

    const resolveStatusVideo = status => {
      if (status === 'primary') return 'primary'
      if (status === 'R') return 'error'
      if (status === 'secondary') return 'secondary'
      if (status === 'P') return 'warning'
      if (status === 'S') return 'info'
      if (status === 'A') return 'success'

      return 'primary'
    }

    const onShow = async item => {
      const paymentId = item.transaction.payment.id

      let response = null
      if (!!item.transaction.user_app || (!item.transaction.user_app && !item.transaction.user)) {
        response = await getPaymentUserApp(paymentId)
      } else {
        response = await getPaymentById(paymentId)
      }
      if (response.ok) {
        const payment = response.data
        if (!!item.transaction.user_app || (!item.transaction.user_app && !item.transaction.user)) {
          router.push({
            name: 'views-fanaty-payment-preview',
            params: {
              id: paymentId,
              dataParams: payment,
              dataVideo: item,
              dataTransaction: props.dataTransaction,
              option: 2,
            },
          })
        } else {
          router.push({
            name: 'views-payment-preview',
            params: {
              id: paymentId,
              dataParams: payment,
              dataVideo: item,
              option: 2,
            },
          })
        }
      }
    }

    const copy = link => {
      let copyValue = null
      if (link === 'landing_app_link') copyValue = refCopy.value.value
      else copyValue = refCopyVideo.value.value

      copyText(copyValue, undefined, e => {
        if (e) {
          error(vm.$t('tooltip.not_copy'))
        } else {
          success(vm.$t('tooltip.copied'))
        }
      })
    }

    return {
      // data
      form,
      statusOptions,
      refCopy,
      refCopyVideo,

      // computed
      dataComputed,
      computedUser,

      // methods
      avatarText,
      resolveStatusVariant,
      resolveStatusVideo,
      onShow,
      copy,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
        mdiContentCopy,
      },
    }
  },
}
</script>

<style lang="scss" scope>
div .v-input__prepend-outer {
  margin: 0px 8px 0px 0px !important;
}
</style>
