<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text
      v-if="dataComputed.video_type !== 'S'"
      class="py-9 px-md-8 px-0 just"
    >
      <v-card
        :color="$vuetify.theme.dark ? '#1d1d1d' : '#fff'"
        class="rounded-lg mx-auto card-width"
        elevation="7"
      >
        <v-card-text class="pa-3">
          <div>
            <div
              class="ms-0 width-full"
            >
              <v-row class="py-1 px-3">
                <v-col
                  cols="12"
                  class="pa-1 d-flex justify-space-between align-center"
                >
                  <div class="d-flex align-center">
                    <v-avatar
                      :color="dataComputed.facility_avatar ? '' : 'secondary'"
                      :class="dataComputed.facility_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
                      size="32"
                    >
                      <v-img
                        v-if="dataComputed.facility_avatar"
                        :src="dataComputed.facility_avatar"
                      ></v-img>
                      <span
                        v-else
                        class="font-weight-medium"
                      >{{ avatarText(dataComputed.facility_name) }}</span>
                    </v-avatar>
                    <v-col>
                      <v-row>
                        <span class="text-sm font-weight-bold mb-1">{{ dataComputed.video_date }}</span>
                      </v-row>
                      <v-row>
                        <span class="text-xs">{{ dataComputed.field_name }}</span>
                      </v-row>
                    </v-col>
                  </div>
                  <div v-if="showPlayer">
                    <v-btn
                      color="secondary"
                      small
                      class="me-2"
                      @click="downloadVideo(dataComputed.link)"
                    >
                      {{ t('tooltip.download') }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

        <video-player
          v-if="tab === 1 && showPlayer"
          ref="refVideoPlayer"
          class="video-player-box"
          :options="playerOptions"
          :playsinline="true"
          custom-event-name="customstatechangedeventname"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @ready="playerReadied"
        ></video-player>

        <v-card-text
          v-if="!showPlayer"
          class="pa-3 pb-7 text-center"
        >
          {{ t('videos.video_not_available') }}
        </v-card-text>

        <v-card-actions
          v-if="showPlayer"
          class="dense mt-2"
        >
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-space-between my-3"
            />
          </v-row>
        </v-card-actions>
      </v-card>
    </v-card-text>

    <!-- <v-divider></v-divider> -->

    <v-card-text
      v-if="dataComputed.video_type === 'S'"
      class="py-9 px-md-8 px-0 just"
    >
      <v-card
        :color="$vuetify.theme.dark ? '#1d1d1d' : '#fff'"
        class="rounded-lg mx-auto card-width"
        elevation="7"
      >
        <v-card-text class="pa-3">
          <div>
            <div
              class="ms-0 width-full"
            >
              <v-row class="py-1 px-3">
                <v-col
                  cols="12"
                  md="4"
                  class="pa-1 d-flex justify-space-between align-center"
                >
                  <div class="d-flex align-center">
                    <v-avatar
                      :color="dataComputed.facility_avatar ? '' : 'secondary'"
                      :class="dataComputed.facility_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
                      size="32"
                    >
                      <v-img
                        v-if="dataComputed.facility_avatar"
                        :src="dataComputed.facility_avatar"
                      ></v-img>
                      <span
                        v-else
                        class="font-weight-medium"
                      >{{ avatarText(dataComputed.facility_name) }}</span>
                    </v-avatar>
                    <v-col>
                      <v-row>
                        <span class="text-sm font-weight-bold mb-1">{{ dataComputed.video_date }}</span>
                      </v-row>
                      <v-row>
                        <span class="text-xs">{{ dataComputed.field_name }}</span>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-center"
                >
                  <span
                    v-if="showPlayer"
                    class="text-sm font-weight-bold mb-1"
                  >{{ computedLeftRightTitle }}</span>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-end"
                >
                  <v-btn
                    v-if="showPlayer"
                    color="secondary"
                    small
                    class="me-2"
                    @click="downloadVideo(computedLeftRightLink)"
                  >
                    {{ t('tooltip.download') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

        <video-player
          v-if="isLeftRight === 'L' && tab === 1 && showPlayer"
          ref="refVideoPlayerLeft"
          class="video-player-box"
          :options="playerOptionsLeft"
          :playsinline="true"
          custom-event-name="customstatechangedeventname"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @ready="playerReadied"
        ></video-player>

        <video-player
          v-if="isLeftRight === 'R' && tab === 1 && showPlayer"
          ref="refVideoPlayerRight"
          class="video-player-box"
          :options="playerOptionsRight"
          :playsinline="true"
          custom-event-name="customstatechangedeventname"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @ready="playerReadied"
        ></video-player>

        <v-card-text
          v-if="!showPlayer"
          class="pa-3 pb-7 text-center"
        >
          {{ t('videos.video_not_available') }}
        </v-card-text>

        <v-card-actions
          v-if="dataComputed.right_video_url !== dataComputed.left_video_url && showPlayer"
          class="dense mt-2"
        >
          <v-row justify="center">
            <v-col
              cols="12"
              md="3"
              class="d-flex justify-space-between"
            >
              <v-btn
                block
                color="secondary"
                @click="isLeftRight = 'L'"
              >
                Left
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="d-flex justify-space-between"
            >
              <v-btn
                block
                color="secondary"
                @click="isLeftRight = 'R'"
              >
                Right
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { downloadVideo } from '@core/utils'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    showPlayer: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useUtils()
    const dataComputed = computed(() => props.dataParams)

    const refVideoPlayer = ref(null)
    const refVideoPlayerLeft = ref(null)
    const refVideoPlayerRight = ref(null)
    const player = computed(() => refVideoPlayer.value.player)
    const refVideo = ref(null)
    const initdone = ref(false)

    const playerOptions = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
    })

    const isLeftRight = ref('L')
    const time = ref(0)
    const computedLeftRightTitle = computed(() => {
      if (isLeftRight.value === 'L') return t('videos.left_video')

      return t('videos.right_video')
    })
    const computedLeftRightLink = computed(() => {
      if (isLeftRight.value === 'L') return dataComputed.value.left_video_url

      return dataComputed.value.right_video_url
    })
    const playerOptionsLeft = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
    })
    const playerOptionsRight = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
    })

    onMounted(async () => {
      playerOptions.value.sources.push({
        type: 'video/mp4',
        src: dataComputed.value.link,
      })
      playerOptions.value.poster = dataComputed.value.thumbnail

      if (dataComputed.value.video_type === 'S') {
        playerOptionsLeft.value.sources.push({
          type: 'video/mp4',
          src: dataComputed.value.left_video_url,
        })
        playerOptionsLeft.value.poster = dataComputed.value.left_thumbnail

        playerOptionsRight.value.sources.push({
          type: 'video/mp4',
          src: dataComputed.value.right_video_url,
        })
        playerOptionsRight.value.poster = dataComputed.value.right_thumbnail
      }
    })

    // listen event
    const onPlayerTimeupdate = auxPlayer => {
      time.value = auxPlayer.currentTime()
    }

    const onPlayerCanplaythrough = auxPlayer => {
      if (!initdone.value && dataComputed.value && dataComputed.value.video_type === 'H') {
        auxPlayer.currentTime(10)
        initdone.value = true
      }
    }

    // player is ready
    const playerReadied = auxPlayer => {
      if (!initdone.value && dataComputed.value && dataComputed.value.video_type === 'H') {
        auxPlayer.currentTime(10)
        initdone.value = true
      }

      if (dataComputed.value.video_type === 'S' && time.value > 0) {
        auxPlayer.currentTime(time.value)
      }
    }

    return {
      // data
      refVideoPlayer,
      refVideoPlayerLeft,
      refVideoPlayerRight,
      player,
      refVideo,
      initdone,
      playerOptions,

      isLeftRight,
      time,
      playerOptionsLeft,
      playerOptionsRight,

      // computed
      dataComputed,
      computedLeftRightTitle,
      computedLeftRightLink,

      // methods
      avatarText,
      onPlayerTimeupdate,
      onPlayerCanplaythrough,
      playerReadied,
      downloadVideo,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style scope>
.video-player-box .video-js {
  width: 100% !important;
  height: 56vh !important;
}

.card-width {
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 35vh !important;
  }

  .card-width {
    width: 100%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 35vh !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 35vh !important;
  }

  .card-width {
    width: 100%;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 40vh !important;
  }

  .card-width {
    width: 70%;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .video-player-box .video-js {
    width: 100% !important;
    height: 40vh !important;
  }

  .card-width {
    width: 70%;
  }
}
</style>
